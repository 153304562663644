import React from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandsGroupStyle={props.grouping}
      thousandSeparator
      isNumericString
      allowNegative={props.allowNegative || false}
      prefix={props.prefix}
      suffix={props.suffix}
      className={props.className}
    />
  )
})

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export const NumberFormatCustomText = props => {
  return (
    <NumberFormat
      value={props.value}
      prefix={props.currency + " "}
      getInputRef={props.inputRef}
      thousandSeparator
      thousandsGroupStyle={props.grouping}
      isNumericString
      displayType="text"
      type="text"
      className={props.className}
    />
  )
}
