import React from "react"

import "chart.js/auto"
import { Chart } from "react-chartjs-2"
import Paper from "@mui/material/Paper"

import { useTheme } from "@emotion/react"

const ChartAmort = props => {
  const {
    rows,
    arrYearlyInterestSum,
    arrYearlyPrincipleSum,
    arrYearlyPrepaySum,
    arrYearlyClosingBalance,
  } = props

  const theme = useTheme()

  const data = [
    {
      type: "Interest",
      data: rows.map(r => r.interestSum),
    },
    {
      type: "Principle",
      data: rows.map(r => r.principleSum),
    },
    {
      type: "Prepay",
      data: rows.map(r => r.prepaySum),
    },
    {
      type: "Balance",
      data: rows.map(r => r.closingBalance),
    },
  ]

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "4px 4px",
          display: "flex",
          alignItems: "center",
          width: 700,
        }}
      >
        <Chart
          data={{
            labels: rows.map(r => r.yearIndex + 1),
            datasets: [
              {
                type: "bar",
                label: data[0].type,
                xAxisID: "x2",
                yAxisID: "y1",
                data: data[0].data,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
                hoverBorderWidth: 2,
                barPercentage: 1,
                order: 2,
                stack: "s1",
              },
              {
                type: "bar",
                label: data[1].type,
                xAxisID: "x2",
                yAxisID: "y1",
                data: data[1].data,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
                hoverBorderWidth: 2,
                barPercentage: 1,
                order: 2,
                stack: "s1",
              },
              {
                type: "bar",
                label: data[2].type,
                xAxisID: "x2",
                yAxisID: "y1",
                data: data[2].data,
                backgroundColor: "rgba(153, 102, 255, 0.2)",
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 1,
                hoverBorderWidth: 2,
                barPercentage: 1,
                order: 2,
                stack: "s1",
              },
              {
                type: "line",
                label: data[3].type,
                xAxisID: "x2",
                yAxisID: "y2",
                data: data[3].data,
                backgroundColor: "rgba(255, 0, 0, 0.5)",
                borderColor: "rgba(255, 0, 0, 1)",
                borderWidth: 1,
                pointRadius: 4,
                pointHoverRadius: 6,
                barPercentage: 1,
                order: 1,
                stack: "s1",
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: true,

            scales: {
              y2: {
                position: "right", // `axis` is determined by the position as `'y'`
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
              y1: {
                title: {
                  display: true,
                  text: "Yearly amount",
                  color: theme.palette.text.primary,
                },
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
              x2: {
                title: {
                  display: true,
                  text: "Year",
                  color: theme.palette.text.primary,
                },
                ticks: { color: theme.palette.text.primary },
                offset: true,
                grid: {
                  display: false,
                },
              },
            },
            color: theme.palette.text.primary,
            plugins: {
              tooltip: {
                callbacks: {
                  title: function (context) {
                    return (
                      "Year " +
                      (context[0].parsed.x + 1) +
                      " : " +
                      rows[context[0].parsed.x].yearNum
                    )
                  },
                  label: function (context) {
                    let label = context.dataset.label || ""
                    if (context.dataset.label === "Interest") {
                      label =
                        "Interest : " +
                        arrYearlyInterestSum[context.parsed.x]?.current
                          ?.textContent
                    } else if (context.dataset.label === "Principle") {
                      label =
                        "Principle : " +
                        arrYearlyPrincipleSum[context.parsed.x]?.current
                          ?.textContent
                    } else if (context.dataset.label === "Prepay") {
                      label =
                        "Prepay : " +
                        arrYearlyPrepaySum[context.parsed.x]?.current
                          ?.textContent
                    } else if (context.dataset.label === "Balance") {
                      label =
                        "Balance : " +
                        arrYearlyClosingBalance[context.parsed.x]?.current
                          ?.textContent
                    }

                    return label
                  },
                },
              },
            },
          }}
        />
      </Paper>
    </>
  )
}

export default ChartAmort
