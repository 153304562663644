import React from "react"
import { Table, TableHead, TableRow, TableBody } from "@mui/material"
import { StyledTableRowStrips } from "./util/StyledTableRowStrips"
import TableCellSmaller from "./util/table-cell-smaller"
import { NumberFormatCustomText } from "./util/NumberFormatCustom"

const ShowFrequency = props => {
  const { rows, grouping, currency } = props

  return (
    <>
      <Table size="small" aria-label="Monthwise EMI">
        <TableHead>
          <TableRow>
            <TableCellSmaller>Month-Year</TableCellSmaller>
            <TableCellSmaller align="right">Prepay amount</TableCellSmaller>
            <TableCellSmaller align="right">Closing principle</TableCellSmaller>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            return row.history.map(history => {
              if (history.freqAmount > 0) {
                return (
                  <StyledTableRowStrips key={history.emiNo}>
                    <TableCellSmaller component="th" scope="row">
                      {history.monthYear}
                    </TableCellSmaller>
                    <TableCellSmaller align="right">
                      <NumberFormatCustomText
                        value={history.freqAmount}
                        grouping={grouping}
                        currency={currency}
                      />
                    </TableCellSmaller>
                    <TableCellSmaller align="right">
                      <NumberFormatCustomText
                        value={history.closingP}
                        grouping={grouping}
                        currency={currency}
                      />
                    </TableCellSmaller>
                  </StyledTableRowStrips>
                )
              }
              return null
            })
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default ShowFrequency
