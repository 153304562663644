export const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "INR",
    label: "₹",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "GBP",
    label: "£",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "lakh",
    label: "xx,xx,xxx",
  },
  {
    value: "thousand",
    label: "xxx,xxx",
  },
  {
    value: "wan",
    label: "xxxx,xxxx",
  },
]

export const getGroupingForCurrency = curr => {
  if (curr === "₹" || curr === "xx,xx,xxx") return "lakh"
  if (curr === "¥" || curr === "xxxx,xxxx") return "wan"
  return "thousand"
}
