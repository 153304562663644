import React from "react"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import Divider from "@mui/material/Divider"

import { remove_data, store_data } from "./util/StorageService"

const PLAN_MENU_HEIGHT = 64

const SEPERATOR = ".planDisplayName~"

const PlanSwitchMenu = props => {
  const { plansList, currentActive, setPlanData } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = event => {
    setAnchorEl(null)
    if (!event.target.textContent) return

    let newRecent = event.target.outerHTML.substr(
      event.target.outerHTML.indexOf("uniquename=") + "uniquename=".length + 1
    )
    newRecent = newRecent.substr(0, newRecent.indexOf(SEPERATOR))
    store_data(newRecent, "recent")
    setPlanData(newRecent)
  }
  const handleNewPlanClick = () => {
    setAnchorEl(null)
    remove_data("recent")
    remove_data("latest.prepays")
    setPlanData("latest")
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="secondary"
      >
        <FormatListBulletedIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: PLAN_MENU_HEIGHT * 4.5,
            width: "30ch",
          },
        }}
      >
        <MenuItem onClick={handleNewPlanClick}>New plan</MenuItem>
        <Divider />
        {plansList.map(option => (
          <MenuItem
            key={option}
            uniquename={option}
            selected={option.startsWith(currentActive)}
            onClick={handleClose}
          >
            {option.substr(option.indexOf(SEPERATOR) + SEPERATOR.length)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default PlanSwitchMenu
