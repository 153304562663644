import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Planner from "../components/planner"

const BlogIndex = () => {
  return (
    <>
      <Layout>
        <Seo title="Prepay planner" />
        <Planner />
      </Layout>
    </>
  )
}

export default BlogIndex
