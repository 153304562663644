import React, { forwardRef, useImperativeHandle, useRef } from "react"
import loadable from "@loadable/component"

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"
import { Paper } from "@mui/material"
import Switch from "@mui/material/Switch"
import { NumberFormatCustomText } from "./util/NumberFormatCustom"
import {
  format,
  addMonths,
  addYears,
  subDays,
  differenceInDays,
  startOfMonth,
} from "date-fns"
import ChartPie from "./chart-pie"
import ChartAmort from "./chart-amort"
import { Stack } from "@mui/material"
import { Skeleton } from "@mui/material"
import PrepayFrequency from "./prepay-frequency"
import Box from "@mui/material/Box"
import { Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import LegendForRowBgColors from "./util/LegendForRowBgColors"

const totalPrincipleRef = React.createRef()
const totalInterestRef = React.createRef()
const prepaymentRef = React.createRef()
const totalAmountRef = React.createRef()

const arrYearlyInterestSum = []
const arrYearlyPrincipleSum = []
const arrYearlyPrepaySum = []
const arrYearlyClosingBalance = []

const AmortizationSchedule = forwardRef((props, ref) => {
  const {
    principle,
    months,
    rate,
    grouping,
    displayCurrency,
    startMonth,
    prepays,
    sliderUpdating,
    handlePrepayChange,
    freqAmount,
    setFreqAmount,
    freqMonths,
    setFreqMonths,
    freqStepUpAmount,
    setFreqStepUpAmount,
    freqStepUpTimes,
    setFreqStepUpTimes,
    freqStartMonthOffset,
    setFreqStartMonthOffset,
  } = props

  const years = Math.ceil(months / 12)
  const [enablePrepay, setEnablePrepay] = React.useState(true)

  const roi = rate / 12 / 100
  const emi = Math.ceil(
    (principle * roi * (1 + roi) ** months) / ((1 + roi) ** months - 1)
  )

  const rows = []
  for (let i = 0; i < years; i++) {
    rows[i] = createData(
      i,
      principle,
      roi,
      emi,
      rows,
      startMonth,
      prepays,
      enablePrepay,
      freqAmount,
      freqMonths,
      freqStartMonthOffset,
      freqStepUpAmount,
      freqStepUpTimes
    )
  }

  const totalInterest = rows.reduce((a, b) => a + (b["interestSum"] || 0), 0)
  const totalInterestIfNoPrepay = rows.reduce(
    (a, b) => a + (b["interestSumIfNoPrepay"] || 0),
    0
  )
  const totalprinciple = rows.reduce((a, b) => a + (b["principleSum"] || 0), 0)
  const totalprincipleIfNoPrepay = rows.reduce(
    (a, b) => a + (b["principleSumIfNoPrepay"] || 0),
    0
  )
  const totalprepay = rows.reduce((a, b) => a + (b["prepaySum"] || 0), 0)
  let lastM = "MMM yyyy"
  for (let i = 0; i < years; i++) {
    let yearFound = false
    for (let j = 0; j < rows[i].history.length; j++) {
      if (rows[i].history[j].closingP <= 0) {
        lastM = rows[i].history[j].monthYear
        yearFound = true
        break
      }
    }
    if (yearFound) break
  }
  const lastMonth = lastM
  const dtLastMonthIfNoPrepay = addMonths(startMonth, months - 1)
  const lastMonthIfNoPrepay = format(dtLastMonthIfNoPrepay, "MMM yyyy")

  const totalAmount = totalprinciple + totalInterest + totalprepay
  const totalAmountIfNoPrepay =
    totalprincipleIfNoPrepay + totalInterestIfNoPrepay

  const refPrepayFrequency = useRef()

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    adjustFreqStartMonth(newStartMonth, newFreqStartMonthOffset) {
      refPrepayFrequency.current.adjustFreqStartMonth(
        newStartMonth,
        newFreqStartMonthOffset
      )
    },
  }))

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Box sx={{ p: 1, px: 2, border: "1px dashed grey" }}>
            <Typography sx={{ fontSize: 16 }} color="text.primary">
              {`Monthly EMI  `}
              <NumberFormatCustomText
                value={emi}
                grouping={grouping}
                currency={displayCurrency}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6} align="right">
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  {sliderUpdating ? (
                    <Skeleton variant="rectangular" width={350} height={350} />
                  ) : (
                    <ChartPie
                      totalInterestRef={totalInterestRef}
                      totalPrincipleRef={totalPrincipleRef}
                      prepaymentRef={prepaymentRef}
                      totalAmountRef={totalAmountRef}
                      totalInterest={totalInterest}
                      totalprinciple={totalprinciple}
                      totalprepay={totalprepay}
                      totalAmount={totalAmount}
                    />
                  )}

                  {sliderUpdating ? (
                    <Skeleton variant="rectangular" width={700} height={350} />
                  ) : (
                    <ChartAmort
                      rows={rows}
                      arrYearlyInterestSum={arrYearlyInterestSum}
                      arrYearlyPrincipleSum={arrYearlyPrincipleSum}
                      arrYearlyPrepaySum={arrYearlyPrepaySum}
                      arrYearlyClosingBalance={arrYearlyClosingBalance}
                    />
                  )}
                </Stack>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Monthly EMI</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                <NumberFormatCustomText
                  value={emi}
                  grouping={grouping}
                  currency={displayCurrency}
                />
              </TableCell>

              <TableCell rowSpan={6} colSpan={3} align="center">
                <PrepayFrequency
                  ref={refPrepayFrequency}
                  rows={rows}
                  currency={displayCurrency}
                  grouping={grouping}
                  enablePrepay={enablePrepay}
                  startMonth={startMonth}
                  dtLastMonthIfNoPrepay={dtLastMonthIfNoPrepay}
                  freqAmount={freqAmount}
                  setFreqAmount={setFreqAmount}
                  freqMonths={freqMonths}
                  setFreqMonths={setFreqMonths}
                  freqStepUpAmount={freqStepUpAmount}
                  setFreqStepUpAmount={setFreqStepUpAmount}
                  freqStepUpTimes={freqStepUpTimes}
                  setFreqStepUpTimes={setFreqStepUpTimes}
                  freqStartMonthOffset={freqStartMonthOffset}
                  setFreqStartMonthOffset={setFreqStartMonthOffset}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Σ Prepayment
                <Switch
                  checked={enablePrepay}
                  onChange={event => {
                    setEnablePrepay(event.target.checked)
                  }}
                  color="secondary"
                  inputProps={{ "aria-label": "enable-prepay" }}
                  size="small"
                />
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 160 }}></TableCell>
              <TableCell align="right">
                <NumberFormatCustomText
                  value={totalprepay}
                  grouping={grouping}
                  currency={displayCurrency}
                  inputRef={prepaymentRef}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Σ Principle</TableCell>
              <TableCell align="right" className="strike-text">
                {enablePrepay && totalprepay > 0 && (
                  <>
                    (
                    <NumberFormatCustomText
                      value={totalprincipleIfNoPrepay}
                      grouping={grouping}
                      currency={displayCurrency}
                    />
                    )
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                <NumberFormatCustomText
                  value={totalprinciple}
                  grouping={grouping}
                  currency={displayCurrency}
                  inputRef={totalPrincipleRef}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Σ Interest</TableCell>
              <TableCell align="right" className="strike-text">
                {enablePrepay && totalprepay > 0 && (
                  <>
                    (
                    <NumberFormatCustomText
                      value={totalInterestIfNoPrepay}
                      grouping={grouping}
                      currency={displayCurrency}
                    />
                    )
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                <NumberFormatCustomText
                  value={totalInterest}
                  grouping={grouping}
                  currency={displayCurrency}
                  inputRef={totalInterestRef}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Σ Amount</TableCell>
              <TableCell align="right" className="strike-text">
                {enablePrepay && totalprepay > 0 && (
                  <>
                    (
                    <NumberFormatCustomText
                      value={totalAmountIfNoPrepay}
                      grouping={grouping}
                      currency={displayCurrency}
                    />
                    )
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                <NumberFormatCustomText
                  value={totalAmount}
                  grouping={grouping}
                  currency={displayCurrency}
                  inputRef={totalAmountRef}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Loan closure</TableCell>
              <TableCell align="right" className="strike-text">
                {enablePrepay && totalprepay > 0
                  ? `(${lastMonthIfNoPrepay})`
                  : ""}
              </TableCell>
              <TableCell align="right">{lastMonth}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell />
              <TableCell>Year</TableCell>
              <TableCell align="right">Σ Interest</TableCell>
              <TableCell align="right">Σ Principle</TableCell>
              <TableCell align="right">Σ Prepayment</TableCell>
              <TableCell align="right">Closing principle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row
                key={row.yearIndex}
                row={row}
                grouping={grouping}
                currency={displayCurrency}
                handlePrepayChange={handlePrepayChange}
                enablePrepay={enablePrepay}
                sliderUpdating={sliderUpdating}
                yearlyInterestSum={arrYearlyInterestSum[row.yearIndex]}
                yearlyPrincipleSum={arrYearlyPrincipleSum[row.yearIndex]}
                yearlyPrepaySum={arrYearlyPrepaySum[row.yearIndex]}
                yearlyClosingBalance={arrYearlyClosingBalance[row.yearIndex]}
              />
            ))}
          </TableBody>
          <caption>
            <LegendForRowBgColors />
            <br />
            <br />
            * Disclaimer: Amortization schedule and graphs given above are only
            for reference purpose.
            <br />
            Please always consider consulation with your financial advisor when
            deciding upon financial matters.
            <br />
            Actual amount payable may differ according to interest calculation
            formula in your region and other factors.
          </caption>
        </Table>
      </TableContainer>
    </>
  )
})

function createData(
  yearIndex,
  principle,
  roi,
  emi,
  rows,
  startMonth,
  prepays,
  enablePrepay,
  p_freqAmount,
  freqMonths,
  freqStartMonthOffset,
  freqStepUpAmount,
  freqStepUpTimes
) {
  let isCurrentYear = false
  let prepayCount = 0
  const yearData = []
  for (let i = 0; i < 12; i++) {
    const emiNo = yearIndex * 12 + i + 1

    const dtMonth = addMonths(startMonth, yearIndex * 12 + i)
    const monthYear = format(dtMonth, "MMM yyyy")

    let isCurrentMonth = false
    if (differenceInDays(startOfMonth(new Date()), dtMonth) === 0) {
      isCurrentMonth = true
      isCurrentYear = true
    }

    let outstandingP = 0
    let outstandingPIfNoPrepay = 0
    if (yearIndex === 0 && i === 0) {
      outstandingP = principle
      outstandingPIfNoPrepay = principle
    } else if (i === 0) {
      outstandingP = rows[yearIndex - 1].history[11].closingP
      outstandingPIfNoPrepay =
        rows[yearIndex - 1].history[11].closingPIfNoPrepay
    } else {
      outstandingP = yearData[i - 1].closingP
      outstandingPIfNoPrepay = yearData[i - 1].closingPIfNoPrepay
    }

    const interestP = Math.round(outstandingP * roi)
    const interestPIfNoPrepay = Math.round(outstandingPIfNoPrepay * roi)

    let principleP = emi - interestP
    let principlePIfNoPrepay = emi - interestPIfNoPrepay

    if (outstandingP < emi) {
      principleP = outstandingP
    }
    if (outstandingPIfNoPrepay < emi) {
      principlePIfNoPrepay = outstandingPIfNoPrepay
    }

    let freqAmount = 0
    if (enablePrepay && p_freqAmount > 0) {
      let isScheduledMonth = false
      if (emiNo - 1 >= freqStartMonthOffset) {
        if (emiNo - 1 === freqStartMonthOffset) {
          isScheduledMonth = true
        } else if ((emiNo - 1 - freqStartMonthOffset) % freqMonths === 0) {
          isScheduledMonth = true
        }
      }

      if (isScheduledMonth) {
        freqAmount = p_freqAmount

        if (freqStepUpAmount !== 0) {
          const nthTime = Math.floor(
            (emiNo - 1 - freqStartMonthOffset) / freqMonths
          )
          freqAmount =
            freqAmount +
            freqStepUpAmount * Math.floor(nthTime / freqStepUpTimes)

          if (freqAmount < 0) freqAmount = 0
        }

        if (freqAmount > outstandingP - principleP) {
          freqAmount = outstandingP - principleP
        }
        if (freqAmount > 0) prepayCount++
      }
    }

    let prepayAmount = enablePrepay ? prepays[emiNo - 1] : 0
    if (prepayAmount > outstandingP - principleP - freqAmount)
      prepayAmount = outstandingP - principleP - freqAmount

    if (prepayAmount > 0) prepayCount++

    const closingP = outstandingP - principleP - prepayAmount - freqAmount
    const closingPIfNoPrepay = outstandingPIfNoPrepay - principlePIfNoPrepay

    yearData[i] = {
      emiNo,
      monthYear,
      isCurrentMonth,
      outstandingP,
      outstandingPIfNoPrepay,
      interestP,
      interestPIfNoPrepay,
      principleP,
      principlePIfNoPrepay,
      prepayAmount,
      freqAmount,
      closingP,
      closingPIfNoPrepay,
    }
  }

  const interestSum = yearData.reduce((a, b) => a + (b["interestP"] || 0), 0)
  const interestSumIfNoPrepay = yearData.reduce(
    (a, b) => a + (b["interestPIfNoPrepay"] || 0),
    0
  )
  const principleSum = yearData.reduce((a, b) => a + (b["principleP"] || 0), 0)
  const principleSumIfNoPrepay = yearData.reduce(
    (a, b) => a + (b["principlePIfNoPrepay"] || 0),
    0
  )
  let prepaySum = yearData.reduce((a, b) => a + (b["prepayAmount"] || 0), 0)
  prepaySum =
    prepaySum + yearData.reduce((a, b) => a + (b["freqAmount"] || 0), 0)
  const closingBalance = yearData[11].closingP

  const yearStart = format(addYears(startMonth, yearIndex), "yyyy")
  const yearEnd = format(
    subDays(addYears(addMonths(startMonth, 12), yearIndex), 1),
    "yyyy"
  )
  const yearNum = yearStart + (yearStart === yearEnd ? "" : "-" + yearEnd)

  arrYearlyInterestSum[yearIndex] = React.createRef()
  arrYearlyPrincipleSum[yearIndex] = React.createRef()
  arrYearlyPrepaySum[yearIndex] = React.createRef()
  arrYearlyClosingBalance[yearIndex] = React.createRef()

  return {
    yearIndex,
    yearNum,
    isCurrentYear,
    interestSum,
    interestSumIfNoPrepay,
    principleSum,
    principleSumIfNoPrepay,
    prepaySum,
    closingBalance,
    prepayCount,
    history: yearData,
  }
}

const Row = loadable(() => import("./month-row"))

export default AmortizationSchedule
