import React from "react"
import { Paper, Typography } from "@mui/material"
import { Grid } from "@mui/material"

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { IconButton } from "@mui/material"
import { Badge } from "@mui/material"

const LegendBox = props => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: 250,
        height: 40,
        lineHeight: "40px",
        textAlign: "center",
        backgroundColor: props.myColor,
      }}
    >
      {props.children}
      {props.text}
    </Paper>
  )
}

const LegendForRowBgColors = () => {
  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography component="span">Legend: </Typography>
      </Grid>
      <Grid item>
        <LegendBox
          text="Current year/month"
          myColor="rgba(75, 192, 192, 0.4)"
        />
      </Grid>
      <Grid item>
        <LegendBox text="Prepayment month" myColor="rgba(153, 102, 255, 0.4)" />
      </Grid>
      <Grid item>
        <LegendBox
          text="Loan closure year/month"
          myColor="rgba(255, 99, 132, 0.4)"
        />
      </Grid>
      <Grid item>
        <LegendBox text="Number of prepays in year">
          <IconButton
            aria-label="Legend for badge"
            size="small"
            color="primary"
            sx={{ mr: 2, p: 0 }}
          >
            <Badge badgeContent={2} color="primary">
              <KeyboardArrowDownIcon />
            </Badge>
          </IconButton>
        </LegendBox>
      </Grid>
    </Grid>
  )
}

export default LegendForRowBgColors
