import React from "react"

import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import { styled } from "@mui/material/styles"

const StyledDisabledButton = styled(Button)(({ theme, isreallydisabled }) => ({
  "&.Mui-disabled": {
    color: isreallydisabled ? "" : theme.palette.text.primary,
    background: isreallydisabled ? "" : theme.palette.background.primary,
    borderColor: isreallydisabled ? "" : theme.palette.primary.main,
  },
}))

const CounterButtons = props => {
  const { value, setValue, minValue, maxValue, isDisabled } = props
  const maxLimit = maxValue || 10
  const minLimit = minValue || 0

  const handleIncrement = () => {
    if (value + 1 <= maxLimit) setValue(value + 1)
  }

  const handleDecrement = () => {
    if (value - 1 >= minLimit) setValue(value - 1)
  }

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button
        sx={{ border: 1 }}
        onClick={handleDecrement}
        disabled={isDisabled}
      >
        -
      </Button>
      <StyledDisabledButton
        disabled
        isreallydisabled={isDisabled ? isDisabled.toString() : undefined}
      >
        {value}
      </StyledDisabledButton>
      <Button
        sx={{ border: 1 }}
        onClick={handleIncrement}
        disabled={isDisabled}
      >
        +
      </Button>
    </ButtonGroup>
  )
}

export default CounterButtons
