import { styled } from "@mui/material/styles"
import TableRow from "@mui/material/TableRow"

export const StyledTableRowStrips = styled(TableRow)(
  ({ theme, backgroundcolor }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: backgroundcolor
        ? backgroundcolor
        : theme.palette.action.hover,
    },
    backgroundColor: backgroundcolor,
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  })
)
