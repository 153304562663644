import React from "react"

import "chart.js/auto"
import { Doughnut } from "react-chartjs-2"

import Paper from "@mui/material/Paper"
import { useTheme } from "@emotion/react"

const ChartPie = props => {
  const {
    totalPrincipleRef,
    totalInterestRef,
    prepaymentRef,
    totalAmountRef,
    totalInterest,
    totalprinciple,
    totalprepay,
    totalAmount,
  } = props

  const theme = useTheme()

  const data = {
    labels: ["Total interest", "Total principle", "Prepayment"],
    datasets: [
      {
        label: "Amount : ",
        data: [totalInterest, totalprinciple, totalprepay],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  }

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx
        ctx.restore()
        var fontSize = (height / 300).toFixed(2)
        ctx.font = fontSize + "em sans-serif"
        ctx.fillStyle = "rgba(153, 102, 255, 0.9)"
        ctx.textBaseline = "top"
        var text = totalAmountRef.current?.textContent,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = Math.round(height / 2 + 18)
        ctx.fillText(text, textX, textY)
        ctx.save()
      },
    },
  ]

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "4px 4px",
          display: "flex",
          alignItems: "center",
          width: 350,
        }}
      >
        <Doughnut
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || ""

                    if (context.label === "Total interest") {
                      label =
                        "Interest : " + totalInterestRef.current?.textContent
                    } else if (context.label === "Total principle") {
                      label =
                        "Principle : " + totalPrincipleRef.current?.textContent
                    } else if (context.label === "Prepayment") {
                      label =
                        "Prepayment : " + prepaymentRef.current?.textContent
                    }

                    return label
                  },
                  afterLabel: function (context) {
                    let amt = 0
                    if (context.label === "Total interest") {
                      amt = totalInterest
                    } else if (context.label === "Total principle") {
                      amt = totalprinciple
                    } else if (context.label === "Prepayment") {
                      amt = totalprepay
                    }
                    return ((amt / totalAmount) * 100).toFixed(2) + " %"
                  },
                },
              },
              legend: {},
            },
            color: theme.palette.text.primary,
          }}
          plugins={plugins}
        />
      </Paper>
    </>
  )
}

export default ChartPie
