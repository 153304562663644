import React from "react"
import Typography from "@mui/material/Typography"

const UsageInstructions = () => {
  return (
    <Typography variant="body2" component="ul" className="center-bullets">
      <li>Select currency or currency grouping for better readability</li>
      <li>Enter total principle amount or remaining principle amount</li>
      <li>Enter loan interest rate and EMI start month</li>
      <li>
        Enter total tenure or remaining tenure in terms of years or months
      </li>
      <li>
        View monthly EMI and amortization schedule as you enter the details
      </li>
      <li>Expand each year and enter planned prepayment amounts</li>
      <li>
        Schedule prepayment amount 'x' every 'm' months starting from month 'mn'
        with step up or step down amount 'y' after every 'n' times
      </li>
      <li>
        Easily compare effects of prepayment with a switch for enable prepayment
      </li>
      <li>
        Save your prepayment plan by giving it a name and easily switch between
        saved plans
      </li>
    </Typography>
  )
}

export default UsageInstructions
