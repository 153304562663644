const isBrowser = typeof window !== "undefined"

export const store_data = (data, key) => {
  if (!isBrowser || !window.localStorage || !window.JSON || !key) {
    return
  }
  localStorage.setItem(key, JSON.stringify(data))
}

export const get_data = key => {
  // console.log("get_data for " + key)
  if (!isBrowser || !window.localStorage || !window.JSON || !key) {
    return
  }
  var item = localStorage.getItem(key)

  if (!item) {
    return
  }

  return JSON.parse(item)
}

export const remove_data = key => {
  if (!isBrowser || !window.localStorage || !window.JSON || !key) {
    return
  }
  localStorage.removeItem(key)
}

export const get_plan_list = () => {
  if (!isBrowser || !window.localStorage || !window.JSON) {
    return
  }
  let arr = []
  for (let [key, value] of Object.entries(localStorage)) {
    if (key.endsWith(".planDisplayName")) {
      arr.push(key + "~" + value.replaceAll('"', ""))
    }
  }
  return arr
}
