import React, { useRef } from "react"

import { currencies, getGroupingForCurrency } from "./../constants/currencies"
import AmortizationSchedule from "./amortizationSchedule"
import PlanSwitchMenu from "./plan-switch-menu"
import { useConfirm } from "material-ui-confirm"

import { TextField } from "@mui/material"
import { Slider } from "@mui/material"
import {
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
} from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import SaveIcon from "@mui/icons-material/Save"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Divider from "@mui/material/Divider"
import { NumberFormatCustom } from "./util/NumberFormatCustom"
import DatePicker from "@mui/lab/DatePicker"
import Grid from "@mui/material/Grid"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { ButtonGroup } from "@mui/material"
import {
  addYears,
  subYears,
  startOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns"
import {
  store_data,
  get_data,
  remove_data,
  get_plan_list,
} from "./util/StorageService"

const roiMarks = [
  {
    value: 2,
    label: "2%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 10,
    label: "10%",
  },
]

const durationMarks = [
  [
    {
      value: 5,
      label: "5",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 30,
      label: "30",
    },
  ],
  [
    {
      value: 60,
      label: "60",
    },
    {
      value: 120,
      label: "120",
    },
    {
      value: 180,
      label: "180",
    },
    {
      value: 240,
      label: "240",
    },
    {
      value: 300,
      label: "300",
    },
    {
      value: 360,
      label: "360",
    },
  ],
]

const MAX_CHAR_PLAN_NAME = 30
const MAX_TENUE_YEARS = 50
const MAX_TENURE_MONTHS = 600
const MAX_ROI = 100

const loadPlanData = newRecent => {
  const tenure = get_data(newRecent + ".tenure") || 20
  const tenureType = get_data(newRecent + ".tenureType") || "years"

  return {
    planName: get_data("recent") || "latest",
    planDisplayName: get_data(newRecent + ".planDisplayName") || "",
    currency: get_data(newRecent + ".currency") || currencies[0].label,
    grouping:
      get_data(newRecent + ".grouping") ||
      getGroupingForCurrency(currencies[0].label),
    loanAmount: get_data(newRecent + ".loanAmount") || 3000000,
    roi: get_data(newRecent + ".roi") || 8.6,
    tenure,
    tenureType,
    startMonth:
      (get_data(newRecent + ".startMonth") &&
        new Date(get_data(newRecent + ".startMonth"))) ||
      startOfMonth(new Date()),
    prepays:
      get_data(newRecent + ".prepays") ||
      Array(Math.ceil(tenureType === "years" ? tenure * 12 : tenure)).fill(0),
    freqAmount: get_data(newRecent + ".freqAmount") || 0,
    freqMonths: get_data(newRecent + ".freqMonths") || 6,
    freqStepUpAmount: get_data(newRecent + ".freqStepUpAmount") || 0,
    freqStepUpTimes: get_data(newRecent + ".freqStepUpTimes") || 1,
    freqStartMonthOffset: get_data(newRecent + ".freqStartMonthOffset") || 0,
  }
}

const stepForRoiSlider = 0.05
const stepForTenureYearsSlider = 0.5
const stepForTenureMonthsSlider = 1

const g_planName = get_data("recent") || "latest"
const g_planData = loadPlanData(g_planName)

const Planner = () => {
  const refAmortizationSchedule = useRef()
  const [planName, setPlanName] = React.useState(g_planData.planName)

  const [planDisplayName, setPlanDisplayName] = React.useState(
    g_planData.planDisplayName
  )

  const [currency, setCurrency] = React.useState(g_planData.currency)
  const displayCurrency = currency
    .replace("xx,xx,xxx", "")
    .replace("xxxx,xxxx", "")
    .replace("xxx,xxx", "")
  const [grouping, setGrouping] = React.useState(g_planData.grouping)
  const [loanAmount, setLoanAmount] = React.useState(g_planData.loanAmount)
  const [roi, setRoi] = React.useState(g_planData.roi)
  const [tenure, setTenure] = React.useState(g_planData.tenure)
  const [tenureType, setTenureType] = React.useState(g_planData.tenureType)

  const months = Math.ceil(tenureType === "years" ? tenure * 12 : tenure)
  const [startMonth, setStartMonth] = React.useState(g_planData.startMonth)

  const [prepays, setPrepays] = React.useState(g_planData.prepays)

  const [freqAmount, setFreqAmount] = React.useState(g_planData.freqAmount)
  const [freqMonths, setFreqMonths] = React.useState(g_planData.freqMonths)
  const [freqStepUpAmount, setFreqStepUpAmount] = React.useState(
    g_planData.freqStepUpAmount
  )
  const [freqStepUpTimes, setFreqStepUpTimes] = React.useState(
    g_planData.freqStepUpTimes
  )
  const [freqStartMonthOffset, setFreqStartMonthOffset] = React.useState(
    g_planData.freqStartMonthOffset
  )

  const desiredPrepayLength = Math.ceil(months / 12) * 12
  if (prepays.length < desiredPrepayLength)
    setPrepays(
      prepays.concat(Array(desiredPrepayLength - prepays.length).fill(0))
    )

  const [plansList, setPlansList] = React.useState(get_plan_list() || [])

  const [sliderUpdating, setSliderUpdating] = React.useState(false)

  const deleteConfirm = useConfirm()

  const calendarStart = subYears(startOfYear(new Date()), 10)
  const calendarEnd = addYears(endOfYear(new Date()), 5)

  const setPlanData = newRecent => {
    const planData = loadPlanData(newRecent)
    setPlanName(planData.planName)
    setPlanDisplayName(planData.planDisplayName)
    setCurrency(planData.currency)
    setGrouping(planData.grouping)
    setLoanAmount(planData.loanAmount)
    setRoi(planData.roi)
    setTenure(planData.tenure)
    setTenureType(planData.tenureType)
    setStartMonth(planData.startMonth)
    setPrepays(planData.prepays)
    setFreqAmount(planData.freqAmount)
    setFreqMonths(planData.freqMonths)
    setFreqStepUpAmount(planData.freqStepUpAmount)
    setFreqStepUpTimes(planData.freqStepUpTimes)
    setFreqStartMonthOffset(planData.freqStartMonthOffset)
    planData.startMonth &&
      refAmortizationSchedule.current.adjustFreqStartMonth(
        planData.startMonth,
        planData.freqStartMonthOffset
      )
  }

  const handlePlanDisplayNameChange = event => {
    if (event.target.value.length <= MAX_CHAR_PLAN_NAME)
      setPlanDisplayName(event.target.value)
  }

  const handleCurrencyChange = event => {
    planName === "latest" && store_data(event.target.value, "latest.currency")
    const grp = getGroupingForCurrency(event.target.value)
    planName === "latest" && store_data(grp, "latest.grouping")

    setCurrency(event.target.value)
    setGrouping(grp)
  }

  const handleAmountChange = event => {
    const value = !event.target.value ? 0 : event.target.value
    planName === "latest" && store_data(value, "latest.loanAmount")
    setLoanAmount(value)
  }

  const handleRoiChange = event => {
    let newVal = event.target.value
    if (typeof newVal === "string") newVal = newVal.replace("%", "")
    if (!newVal) newVal = ""
    newVal = Number(newVal)

    if (newVal <= MAX_ROI) setRoi(newVal)
    else setRoi(MAX_ROI)
  }

  const handleRoiBlur = event => {
    planName === "latest" && store_data(roi, "latest.roi")
  }

  const handleRoiSliderChangeCommited = event => {
    planName === "latest" && store_data(roi, "latest.roi")
    setSliderUpdating(false)
  }

  const incrementRoi = () => {
    let newVal = Math.round((roi + stepForRoiSlider) * 100) / 100 // Used this for rounding issue upto 2 decimal places

    if (newVal <= MAX_ROI) setRoi(newVal)
    else setRoi(MAX_ROI)
  }

  const decrementRoi = () => {
    let newVal = Math.round((roi - stepForRoiSlider) * 100) / 100 // Used this for rounding issue upto 2 decimal places

    if (newVal <= MAX_ROI) setRoi(newVal)
    else setRoi(MAX_ROI)
  }

  const handleYearSliderChangeCommited = event => {
    planName === "latest" && store_data(tenure, "latest.tenure")
    setSliderUpdating(false)
  }

  const handleYearChange = event => {
    let newVal = event.target.value
    if (typeof newVal === "string")
      newVal = newVal.replace(" years", "").replace(" months", "")
    if (!newVal) newVal = "0"
    newVal = Number(newVal)

    if (
      (tenureType === "months" && newVal <= MAX_TENURE_MONTHS) ||
      newVal <= MAX_TENUE_YEARS
    ) {
      setTenure(newVal)
    } else {
      setTenure(tenureType === "months" ? MAX_TENURE_MONTHS : MAX_TENUE_YEARS)
    }
  }

  const handleYearBlur = event => {
    planName === "latest" && store_data(tenure, "latest.tenure")
  }

  const incrementTenure = () => {
    let incrementStep = stepForTenureMonthsSlider
    if (tenureType === "years") incrementStep = stepForTenureYearsSlider

    let newVal = tenure + incrementStep
    if (
      (tenureType === "months" && newVal <= MAX_TENURE_MONTHS) ||
      newVal <= MAX_TENUE_YEARS
    ) {
      setTenure(newVal)
    } else {
      setTenure(tenureType === "months" ? MAX_TENURE_MONTHS : MAX_TENUE_YEARS)
    }
  }

  const decrementTenure = () => {
    let incrementStep = stepForTenureMonthsSlider
    if (tenureType === "years") incrementStep = stepForTenureYearsSlider

    let newVal = tenure - incrementStep
    if (
      (tenureType === "months" && newVal <= MAX_TENURE_MONTHS) ||
      newVal <= MAX_TENUE_YEARS
    ) {
      setTenure(newVal)
    } else {
      setTenure(tenureType === "months" ? MAX_TENURE_MONTHS : MAX_TENUE_YEARS)
    }
  }

  const handleTenureTypeChange = event => {
    if (event.target.value !== tenureType) {
      setTenureType(event.target.value)
      let newTenure = 0
      if (event.target.value === "months") newTenure = Math.ceil(tenure * 12)
      else newTenure = Math.round(tenure / 12)
      setTenure(newTenure)
      planName === "latest" &&
        store_data(event.target.value, "latest.tenureType")
      planName === "latest" && store_data(newTenure, "latest.tenure")
    }
  }

  const startMonthChange = newValue => {
    const value = startOfMonth(newValue)
    planName === "latest" && value && store_data(value, "latest.startMonth")
    value && setStartMonth(value)
    value &&
      refAmortizationSchedule.current.adjustFreqStartMonth(
        value,
        freqStartMonthOffset
      )
  }

  const handlePrepayChange = (index, newValue) => {
    if (typeof prepays === "object" && index < prepays.length) {
      const newArr = [...prepays]
      newValue = !newValue ? 0 : Number(newValue)
      newArr[index] = newValue
      if (planName === "latest" && newArr) {
        store_data(newArr, "latest.prepays")
      }

      newArr && setPrepays(newArr)
    }
  }

  const savePlan = () => {
    let newPlanName = planName
    if (!newPlanName || newPlanName === "latest")
      newPlanName = Date.now() + "" + Math.random()

    const newData = {
      planDisplayName: planDisplayName.trim(),
      currency,
      grouping,
      loanAmount,
      roi,
      tenure,
      tenureType,
      startMonth,
      prepays,
      freqAmount,
      freqMonths,
      freqStepUpAmount,
      freqStepUpTimes,
      freqStartMonthOffset,
    }
    for (var key in newData) {
      if (newData.hasOwnProperty(key)) {
        var val = newData[key]
        store_data(val, newPlanName + "." + key)
      }
    }
    store_data(newPlanName, "recent")
    setPlanName(newPlanName)
    setPlansList(get_plan_list())
  }

  const deletePlan = () => {
    const dataToRemove = {
      planDisplayName,
      currency,
      grouping,
      loanAmount,
      roi,
      tenure,
      tenureType,
      startMonth,
      prepays,
      freqAmount,
      freqMonths,
      freqStepUpAmount,
      freqStepUpTimes,
      freqStartMonthOffset,
    }
    for (var key in dataToRemove) {
      if (dataToRemove.hasOwnProperty(key)) {
        remove_data(planName + "." + key)
      }
    }
    remove_data("recent")
    setPlanData("latest")
    setPlansList(get_plan_list())
  }

  const askDeleteConfirmation = () => {
    deleteConfirm({
      title: "Delete plan",
      description:
        "Would you like to delete the plan : '" + planDisplayName + "'?",
      confirmationText: "Delete",
      dialogProps: { maxWidth: "xs" },
    })
      .then(() => {
        deletePlan()
      })
      .catch(() => {})
  }

  const isInvalidPlanName = val => {
    return !val.trim()
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1, sm: 2, md: 3 }}
        columns={{ xs: 1, sm: 3, md: 3 }}
      >
        <Grid item xs={12} align="center">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: { xs: 320, sm: 400, md: 500 },
            }}
          >
            <PlanSwitchMenu
              plansList={plansList}
              currentActive={planName}
              setPlanData={setPlanData}
            />
            <TextField
              helperText={
                planDisplayName.length <= MAX_CHAR_PLAN_NAME - 1
                  ? ""
                  : "Maximum " + MAX_CHAR_PLAN_NAME + " characters"
              }
              id="plan-name"
              name="plan-name"
              placeholder="New plan name"
              label="Plan name"
              inputProps={{ "aria-label": "new plan name" }}
              value={planDisplayName}
              onChange={handlePlanDisplayNameChange}
              autoComplete="off"
              sx={{ flex: 1 }}
              color="secondary"
            />

            <IconButton
              sx={{ p: "10px" }}
              color="success"
              aria-label="Save plan"
              disabled={isInvalidPlanName(planDisplayName)}
              onClick={() => savePlan()}
            >
              <SaveIcon />
            </IconButton>
            {planName !== "latest" && (
              <>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  color="error"
                  sx={{ p: "10px" }}
                  aria-label="Delete plan"
                  onClick={() => askDeleteConfirmation()}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            component="form"
            sx={{
              p: "8px 16px",
              display: "flex",
              alignItems: "center",
              width: { xs: 320, sm: 400 },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <TextField
                  id="filled-select-currency-native"
                  select
                  label="Currency"
                  value={currency}
                  onChange={handleCurrencyChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="filled"
                  color="secondary"
                >
                  {currencies.map(option => (
                    <option key={option.value} value={option.label}>
                      {option.label}
                      {option.label.includes(",") ? "" : ` - ${option.value}`}
                    </option>
                  ))}
                </TextField>

                <FormControl
                  color="secondary"
                  variant="filled"
                  sx={{ width: { xs: "14ch", sm: "18ch" } }}
                >
                  <InputLabel htmlFor="loan-amount">Loan amount</InputLabel>
                  <FilledInput
                    id="loan-amount"
                    name="loan-amount"
                    value={loanAmount}
                    onChange={handleAmountChange}
                    inputComponent={NumberFormatCustom}
                    inputProps={{
                      grouping: grouping,
                      prefix: displayCurrency + " ",
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} align="center">
                <DatePicker
                  views={["year", "month"]}
                  label="First month of EMI"
                  minDate={calendarStart}
                  maxDate={calendarEnd}
                  value={startMonth}
                  onChange={startMonthChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="filled"
                      color="secondary"
                      helperText={null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            component="form"
            sx={{
              p: "8px 16px",
              display: "flex",
              alignItems: "center",
              width: { xs: 320, sm: 400 },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <FormControl color="secondary" variant="filled">
                  <InputLabel htmlFor="roi">Interest rate</InputLabel>
                  <FilledInput
                    id="roi"
                    name="roi"
                    value={roi.toString()}
                    onBlur={handleRoiBlur}
                    onChange={handleRoiChange}
                    inputComponent={NumberFormatCustom}
                    inputProps={{
                      grouping: grouping,
                      suffix: " %",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <ButtonGroup
                          orientation="vertical"
                          variant="text"
                          aria-label="increment or decrement rate of interest"
                          color="secondary"
                          size="small"
                        >
                          <IconButton
                            aria-label="increment"
                            size="small"
                            onClick={incrementRoi}
                          >
                            <ArrowDropUpIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="decrement"
                            size="small"
                            onClick={decrementRoi}
                          >
                            <ArrowDropDownIcon fontSize="inherit" />
                          </IconButton>
                        </ButtonGroup>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} align="center">
                <Slider
                  aria-label="Interest rate slider"
                  color="secondary"
                  value={roi}
                  step={stepForRoiSlider}
                  min={0.0}
                  max={15}
                  marks={roiMarks}
                  valueLabelDisplay="auto"
                  onChange={event => {
                    setSliderUpdating(true)
                    handleRoiChange(event)
                  }}
                  onChangeCommitted={handleRoiSliderChangeCommited}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            component="form"
            sx={{
              p: "8px 16px",
              display: "flex",
              alignItems: "center",
              width: { xs: 320, sm: 400 },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} align="center">
                <FormControl color="secondary" variant="filled">
                  <InputLabel htmlFor="tenure">Tenure</InputLabel>
                  <FilledInput
                    id="tenure"
                    name="tenure"
                    value={tenure.toString()}
                    onChange={handleYearChange}
                    onBlur={handleYearBlur}
                    inputComponent={NumberFormatCustom}
                    inputProps={{
                      grouping: grouping,
                      suffix: " " + tenureType,
                    }}
                    endAdornment={
                      <InputAdornment position="end" sx={{ gap: 1 }}>
                        <ButtonGroup
                          orientation="vertical"
                          variant="text"
                          aria-label="increment or decrement tenure"
                          color="secondary"
                          size="small"
                        >
                          <IconButton
                            aria-label="increment"
                            size="small"
                            onClick={incrementTenure}
                          >
                            <ArrowDropUpIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="decrement"
                            size="small"
                            onClick={decrementTenure}
                          >
                            <ArrowDropDownIcon fontSize="inherit" />
                          </IconButton>
                        </ButtonGroup>

                        <ToggleButtonGroup
                          color="secondary"
                          value={tenureType}
                          exclusive
                          size="small"
                          onChange={handleTenureTypeChange}
                        >
                          <ToggleButton
                            value="years"
                            sx={{ color: "text.primary" }}
                          >
                            Years
                          </ToggleButton>
                          <ToggleButton
                            value="months"
                            sx={{ color: "text.primary" }}
                          >
                            Months
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Slider
                  aria-label="Tenure slider"
                  color="secondary"
                  value={tenure}
                  step={
                    tenureType === "years"
                      ? stepForTenureYearsSlider
                      : stepForTenureMonthsSlider
                  }
                  min={0}
                  max={tenureType === "years" ? 35 : 420}
                  marks={
                    tenureType === "years" ? durationMarks[0] : durationMarks[1]
                  }
                  valueLabelDisplay="auto"
                  onChange={event => {
                    setSliderUpdating(true)
                    handleYearChange(event)
                  }}
                  onChangeCommitted={handleYearSliderChangeCommited}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <br />

      <AmortizationSchedule
        ref={refAmortizationSchedule}
        principle={Number(loanAmount)}
        months={months}
        rate={Number(roi)}
        grouping={grouping}
        displayCurrency={displayCurrency}
        startMonth={startMonth}
        prepays={prepays}
        sliderUpdating={sliderUpdating}
        handlePrepayChange={handlePrepayChange}
        freqAmount={Number(freqAmount)}
        setFreqAmount={setFreqAmount}
        freqMonths={Number(freqMonths)}
        setFreqMonths={setFreqMonths}
        freqStepUpAmount={Number(freqStepUpAmount)}
        setFreqStepUpAmount={setFreqStepUpAmount}
        freqStepUpTimes={Number(freqStepUpTimes)}
        setFreqStepUpTimes={setFreqStepUpTimes}
        freqStartMonthOffset={Number(freqStartMonthOffset)}
        setFreqStartMonthOffset={setFreqStartMonthOffset}
      />
    </>
  )
}

export default Planner
