import React, { useState, forwardRef, useImperativeHandle } from "react"

import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import {
  FormControl,
  InputAdornment,
  Input,
  TextField,
  InputLabel,
} from "@mui/material"
import { NumberFormatCustom } from "./util/NumberFormatCustom"
import { DatePicker } from "@mui/lab"
import { startOfMonth, addMonths, differenceInMonths } from "date-fns"
import { useConfirm } from "material-ui-confirm"
import ShowFrequency from "./show-frequency"
import CounterButtons from "./util/counter-buttons"
import UsageInstructions from "./usage-instructions"

const PrepayFrequency = forwardRef((props, ref) => {
  const {
    rows,
    currency,
    grouping,
    enablePrepay,
    startMonth,
    dtLastMonthIfNoPrepay,
    freqAmount,
    setFreqAmount,
    freqMonths,
    setFreqMonths,
    freqStepUpAmount,
    setFreqStepUpAmount,
    freqStepUpTimes,
    setFreqStepUpTimes,
    freqStartMonthOffset,
    setFreqStartMonthOffset,
  } = props

  const deleteConfirm = useConfirm()
  const ShowSchedule = useConfirm()
  const usageInstructions = useConfirm()

  const [freqStartMonth, setFreqStartMonth] = useState(
    addMonths(startMonth, freqStartMonthOffset)
  )

  const freqStartMonthChange = newValue => {
    const value = startOfMonth(newValue)
    value && setFreqStartMonth(value)
    value && setFreqStartMonthOffset(differenceInMonths(value, startMonth))
  }

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    adjustFreqStartMonth(newStartMonth, newFreqStartMonthOffset) {
      setFreqStartMonth(addMonths(newStartMonth, newFreqStartMonthOffset))
    },
  }))

  const resetSchedule = () => {
    setFreqAmount(0)
    setFreqStepUpAmount(0)
  }

  const askResetConfirmation = () => {
    deleteConfirm({
      title: "Reset schedule",
      description: "Are you sure to reset all scheduled prepayments?",
      confirmationText: "Reset",
      dialogProps: { maxWidth: "xs" },
    })
      .then(() => {
        resetSchedule()
      })
      .catch(() => {})
  }

  const showUsageInstructions = () => {
    usageInstructions({
      title: "Usage instructions",
      content: <UsageInstructions />,
      cancellationText: "", // To hide cancel button
      cancellationButtonProps: { disabled: true },
      dialogProps: { maxWidth: "xs" },
    })
      .then(() => {})
      .catch(() => {})
  }

  const showSchedulePopup = () => {
    ShowSchedule({
      title: "Scheduled Prepayments",
      content: (
        <ShowFrequency rows={rows} grouping={grouping} currency={currency} />
      ),
      cancellationText: "", // To hide cancel button
      cancellationButtonProps: { disabled: true },
      dialogProps: { maxWidth: "xs" },
    })
      .then(() => {})
      .catch(() => {})
  }

  return (
    <Box sx={{ minWidth: 200 }}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" component="div">
            Schedule Prepay Frequency
          </Typography>
          <Typography sx={{ mb: 1 }} color="text.secondary">
            *****
          </Typography>
          <Typography sx={{ mb: 2 }} color="text.secondary" component="div">
            {`Prepay amount `}
            <FormControl sx={{ width: "12ch" }}>
              <InputLabel htmlFor="prepay-amount"></InputLabel>
              <Input
                size="small"
                id="prepay-amount"
                name="prepay-amount"
                value={freqAmount}
                aria-describedby="prepay-amount"
                inputComponent={NumberFormatCustom}
                inputProps={{
                  grouping: grouping,
                }}
                autoComplete="off"
                startAdornment={
                  <InputAdornment position="start">{currency}</InputAdornment>
                }
                className="no-top-margin"
                disabled={!enablePrepay}
                onChange={event => {
                  setFreqAmount(event.target.value)
                }}
              />
            </FormControl>
          </Typography>
          <Typography sx={{ mb: 2 }} color="text.secondary" component="div">
            {` every `}
            <CounterButtons
              value={freqMonths}
              setValue={setFreqMonths}
              minValue={1}
              maxValue={60}
              isDisabled={!enablePrepay}
            />
            {` months`}
          </Typography>
          <Typography sx={{ mb: 2 }} color="text.secondary" component="div">
            {`starting from `}
            <DatePicker
              views={["year", "month"]}
              minDate={startMonth}
              maxDate={dtLastMonthIfNoPrepay}
              value={freqStartMonth}
              disabled={!enablePrepay}
              onChange={freqStartMonthChange}
              renderInput={params => (
                <TextField
                  {...params}
                  id="dt-prepay-freq-amount"
                  sx={{ width: "16ch" }}
                  variant="standard"
                  color="secondary"
                  helperText={null}
                />
              )}
            />
          </Typography>
          <Typography
            sx={{ mt: 3, mb: 2 }}
            color="text.secondary"
            component="div"
          >
            {`step up(+) or step down(-) with amount `}
            <FormControl sx={{ width: "12ch" }}>
              <InputLabel htmlFor="stepup-amount"></InputLabel>
              <Input
                size="small"
                id="stepup-amount"
                name="stepup-amount"
                value={freqStepUpAmount}
                aria-describedby="stepup-amount"
                inputComponent={NumberFormatCustom}
                inputProps={{
                  grouping: grouping,
                  allowNegative: true,
                }}
                autoComplete="off"
                startAdornment={
                  <InputAdornment position="start">{currency}</InputAdornment>
                }
                className="no-top-margin"
                disabled={!enablePrepay}
                onChange={event => setFreqStepUpAmount(event.target.value)}
              />
            </FormControl>
          </Typography>
          <Typography sx={{ mb: 2 }} color="text.secondary" component="div">
            {`after every `}
            <CounterButtons
              value={freqStepUpTimes}
              setValue={setFreqStepUpTimes}
              minValue={1}
              maxValue={10}
              isDisabled={!enablePrepay}
            />
            {` times`}
          </Typography>
        </CardContent>
        <CardActions style={{ width: "100%", justifyContent: "center" }}>
          <Button size="small" onClick={() => showUsageInstructions()}>
            Usage instructions
          </Button>
          <Button
            size="small"
            disabled={!enablePrepay}
            onClick={showSchedulePopup}
          >
            View schedule
          </Button>
          <Button
            size="small"
            color="error"
            disabled={!enablePrepay}
            onClick={askResetConfirmation}
          >
            Reset schedule
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
})

export default PrepayFrequency
